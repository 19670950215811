import { Avatar, Button, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle, Label, makeStyles, mergeClasses, OverlayDrawer, PopoverProps, SearchBox, shorthands, tokens, useId } from '@fluentui/react-components';
import * as iconModule from '@fluentui/react-icons';
import { Dismiss24Regular, EditRegular, PersonRegular, SearchRegular } from '@fluentui/react-icons';
import { debounce } from 'lodash';
import { cloneElement, FC, useMemo, useState } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { getIconByName } from '../../libs/utils/PersonaIconComponentUtils';

const useClasses = makeStyles({
    listBody: {
        '&:hover': {
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: tokens.colorScrollbarOverlay,
                visibility: 'visible',
            },
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorSubtleBackground,
        },
    },
    drawerFooter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '10px 20px',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    iconList: {
        listStyleType: "none",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        padding: 0,
        margin: "0px",
    },
    iconItem: {
        display: "inline-block",
        padding: 0,
        margin: "0 2px 4px",
        listStyleType: "none",
        position: "relative",
        overflow: "hidden",
        border: "1px solid transparent",
    },
    iconItemSelected: {
        border: "1px dashed",
        ...shorthands.borderColor(tokens.colorBrandForeground1),
    },
    iconRadio: {
        position: "absolute",
        left: "-1000px",
        opacity: 0,
    },
    iconLabel: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        width: "78px",
        padding: "5px",
        height: "70px",
        borderRadius: "3px",
        wordBreak: "break-word",  // will cut off long icon names
        backgroundColor: tokens.colorNeutralBackground4,
        "&:after": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: "2px solid transparent",
            ...shorthands.borderColor(tokens.colorBrandForeground1),
            borderRadius: "3px",
            opacity: 0,
            willChange: "opacity, border-color",
            transition: "opacity ease-out 0.05s",
        },
        "&:hover": {
            "&:after": {
                opacity: 1,
            },
        },
    },
    iconGlyph: {
        fontSize: "24px",
        width: "24px",
        height: "24px",
        marginBottom: "5px",
        color: "inherit",
    },
    iconName: {
        maxWidth: "100%",
        textAlign: "center",
        fontSize: "12px",
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
    },
    iconSearchContainer: {
        display: "flex",
        justifyContent: "center",
    },
    iconSearch: {
        width: "70%",
    },
    drawer: {
        width: "440px",
    },
    editIcon: {
        cursor: "pointer",
    },
    selectedFooterIconContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    selectedFooterIconText: {
        marginRight: "5px",
    },
    selectedFooterIcon: {
        height: "24px",
        width: "24px",
    },
    selectedFooterIconBlank: {
        color: tokens.colorNeutralStroke1
    }
});

interface IFluentIconPickerProps {
    currentIconName?: string;
    iconNameOnChange: (iconName: string) => void;
    focusOnClose?: () => void;
}

interface IconInformation {
    iconName?: string;
    iconComponent: JSX.Element | null;
}

export const FluentIconPicker: FC<IFluentIconPickerProps> = ({
    currentIconName,
    iconNameOnChange,
    focusOnClose
}) => {
    const classes = useClasses();
    const inputId = useId("input");
    const radioGroupName = useId("input");

    const [open, setOpen] = useState(false);
    const handleOpenChange: PopoverProps["onOpenChange"] = (_, data) => {
        setOpen(data.open || false);
        if (!data.open) {
            setSelectedIcon({
                iconName: currentIconName,
                iconComponent: currentIconName ? getIconByName(currentIconName): null
            });
            if (focusOnClose) {
                focusOnClose();
            }
        }
    };

    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

    // All icons list
    const iconList = useMemo<Array<[string, string, JSX.Element | null]>>(() => {
        let icons = Object.keys(iconModule);

        // Select ones that end in "Regular" where the character right before isn't a digit
        icons = icons.filter(iconName => /[^0-9](Regular)$/.test(iconName));

        // Remove "Regular" from icon name and split on Capital letters
        const getDisplayIconName = (iconName: string) => {
            return iconName
                .replace(/(Regular)$/, '')
                .replace(/([A-Z])/g, ' $1')
                .trim();
        };

        const iconNamesAndDisplayNames: Array<[string, string, JSX.Element | null]> = icons.map(iconName => {
            return [iconName, getDisplayIconName(iconName), getIconByName(iconName)];
        });

        return iconNamesAndDisplayNames;
    }, []);

    const filteredIconList = useMemo(() => {
        if (!debouncedSearchValue) {
            return iconList;
        }
        return iconList.filter((iconName) => {
            return iconName[1].toLowerCase().includes(debouncedSearchValue.toLowerCase());
        });
    }, [debouncedSearchValue, iconList]);

    const [avatarIconComponent, setAvatarIconComponent] =
        useState<JSX.Element | null>(currentIconName ? getIconByName(currentIconName) : <PersonRegular />);
    const [selectedIcon, setSelectedIcon] = useState<IconInformation>({
        iconName: currentIconName,
        iconComponent: currentIconName ? getIconByName(currentIconName): null
    });

    const filter = (filterInput: string) => { setDebouncedSearchValue(filterInput); };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const debounced = debounce(filter, 300);
    
    return (
        <>
            <Avatar
                icon={avatarIconComponent}
                badge={{ icon: <EditRegular /> }}
                onClick={() => { setOpen(true); }}
                className={classes.editIcon}
                size={40}
            />
            <OverlayDrawer
                open={open}
                onOpenChange={handleOpenChange}
                position='end'
                className={classes.drawer}
            >
                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={
                            <Button
                                appearance="subtle"
                                aria-label="Close"
                                icon={<Dismiss24Regular />}
                                onClick={() => { setOpen(false); }}
                            />
                        }
                    >
                        Select icon
                    </DrawerHeaderTitle>
                    <div className={classes.iconSearchContainer}>
                        <SearchBox
                            id={inputId}
                            contentBefore={<SearchRegular />}
                            placeholder="Search icons"
                            value={searchValue}
                            onChange={(_, data) => {
                                setSearchValue(data.value);
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                                debounced(data.value);
                            }}
                            className={classes.iconSearch}
                        />
                    </div>
                </DrawerHeader>
                <DrawerBody>
                    <div className={classes.root}>
                        <AutoSizer>
                            {({ height, width }: { height: number, width: number }) => {
                                // 6px scrollbar
                                // 94px icon width = 88 width + 2 L margin + 2 R margin + 1 L border + 1 R border
                                const itemsPerRow = Math.floor((width - 6) / 94);
                                const rowCount = Math.ceil(filteredIconList.length / itemsPerRow);
                                return (
                                    <List
                                        className={classes.listBody}
                                        width={width}
                                        height={height}
                                        rowCount={rowCount}
                                        // 86px height = 70px label height + 5 T label padding + 5 B label padding 
                                        // + 1 T li border + 1 B li border + 4 bottom li margin
                                        rowHeight={86}
                                        rowRenderer={
                                            ({ key, index, style }: { key: string, index: number, style: React.CSSProperties }) => {
                                                const fromIndex = index * itemsPerRow;
                                                const toIndex = Math.min(filteredIconList.length, fromIndex + itemsPerRow);
                                                
                                                return (
                                                    <ul className={classes.iconList} style={style} key={key}>
                                                        {filteredIconList.slice(fromIndex, toIndex).map(iconName => {
                                                            const IconComponent = iconName[2];
                                                            const radioId = `${iconName[0]}-radio`;
                                                            const isSelected = selectedIcon.iconName === iconName[0];
                                                            return (
                                                                <li
                                                                    className={isSelected
                                                                        ? mergeClasses(classes.iconItem, classes.iconItemSelected)
                                                                        : classes.iconItem}
                                                                    key={iconName[0]}
                                                                    title={iconName[1]}
                                                                >
                                                                    <input
                                                                        type='radio'
                                                                        id={radioId}
                                                                        name={radioGroupName}
                                                                        checked={isSelected}
                                                                        className={classes.iconRadio}
                                                                        onChange={() => { 
                                                                            setSelectedIcon({
                                                                                iconName: iconName[0],
                                                                                iconComponent: IconComponent
                                                                            });
                                                                        }}
                                                                    />
                                                                    <Label htmlFor={radioId} className={classes.iconLabel}>
                                                                        <span className={classes.iconGlyph}>
                                                                            {IconComponent}
                                                                        </span>
                                                                        <span className={classes.iconName}>
                                                                            {iconName[1]}
                                                                        </span>
                                                                    </Label>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                );
                                            }
                                        }
                                    />
                                )
                            }}
                        </AutoSizer>
                    </div>
                </DrawerBody>
                <DrawerFooter className={classes.drawerFooter}>
                    <Button
                        aria-label="Clear"
                        onClick={() => {
                            // Clear selected icon
                            setSelectedIcon({
                                iconName: undefined,
                                iconComponent: null
                            });

                            // Clear initial icon
                            currentIconName = "";

                            // Clear the icon displayed
                            setAvatarIconComponent(<PersonRegular />);

                            // Callback to parent to clear icon name setting
                            iconNameOnChange('');

                            setOpen(false);
                            if (focusOnClose) {
                                focusOnClose();
                            }
                        }}
                    >
                        Clear
                    </Button>
                    <div className={classes.selectedFooterIconContainer}>
                        <span
                            className={selectedIcon.iconComponent
                                ? classes.selectedFooterIconText
                                : mergeClasses(classes.selectedFooterIconText, classes.selectedFooterIconBlank)
                            }
                        >
                            Selected:
                        </span>
                        {selectedIcon.iconComponent
                            ? cloneElement(selectedIcon.iconComponent, { className: classes.selectedFooterIcon })
                            : <div className={classes.selectedFooterIcon}></div>
                        }
                    </div>
                    <Button
                        aria-label="Save"
                        appearance='primary'
                        onClick={() => {
                            // Set icon name stored in component for reopening
                            currentIconName = selectedIcon.iconName;

                            // Set the icon displayed
                            setAvatarIconComponent(selectedIcon.iconComponent);

                            // Callback to parent to set icon name
                            iconNameOnChange(selectedIcon.iconName ?? '');
                            
                            setOpen(false);
                            if (focusOnClose) {
                                focusOnClose();
                            }
                        }}
                        disabled={!selectedIcon.iconName}
                    >
                        Save
                    </Button>
                </DrawerFooter>
            </OverlayDrawer>
        </>
    );
};
