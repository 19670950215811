import { Avatar, AvatarSize, Persona } from '@fluentui/react-components';
import { PersonRegular } from '@fluentui/react-icons';
import { FC } from 'react';
import { getIconByName } from '../../libs/utils/PersonaIconComponentUtils';

interface IFluentIconViewerProps {
    iconName?: string;
    iconSize?: number;
    className?: string;
}

export const FluentIconViewer: FC<IFluentIconViewerProps> = ({
    iconName,
    iconSize,
    className
}) => {
    return (
        <Persona
            className={className}
            avatar={{
                icon:
                    <Avatar
                        icon={
                            iconName
                                ? getIconByName(iconName, iconSize)
                                : <PersonRegular style={iconSize ? { fontSize: `${iconSize - 12}px` } : undefined} />
                        }
                        size={iconSize as AvatarSize}
                    />,
            }}
        />
        
    );
};
