import { AvatarProps, makeStyles, mergeClasses, Persona, shorthands, Text, tokens } from '@fluentui/react-components';
import { PersonRegular, ShieldTask16Regular } from '@fluentui/react-icons';
import React, { FC, useState } from 'react';
import { getFriendlyChatName } from '../../../libs/hooks/useChat';
import { usePersonas } from '../../../libs/hooks/usePersonas';
import { getIconByName } from '../../../libs/utils/PersonaIconComponentUtils';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState, store } from '../../../redux/app/store';
import { setActiveRightHandPanel } from '../../../redux/features/app/appSlice';
import { FeatureKeys, RightHandPanel } from '../../../redux/features/app/AppState';
import { setSelectedConversation } from '../../../redux/features/conversations/conversationsSlice';
import { SharedStyles } from '../../../styles';
import { timestampToDateString } from '../../utils/TextUtils';
import { EditChatName } from '../shared/EditChatName';
import { ListItemActions } from './ListItemActions';

const useClasses = makeStyles({
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        //...Breakpoints.small({
        //    justifyContent: 'center',
        //}),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground4Hover,
        },
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXL),
    },
    rootSmall: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        cursor: 'pointer',
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXL),
    },
    avatar: {
        flexShrink: 0,
        width: '32px',
    },
    body: {
        minWidth: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: tokens.spacingHorizontalXS,
        //...Breakpoints.small({
        //    display: 'none',
        //}),
        alignSelf: 'center',
    },
    header: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        ...SharedStyles.overflowEllipsis,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorNeutralForeground1,
    },
    timestamp: {
        flexShrink: 0,
        marginLeft: tokens.spacingHorizontalM,
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground2,
        lineHeight: tokens.lineHeightBase200,
    },
    previewText: {
        ...SharedStyles.overflowEllipsis,
        display: 'block',
        lineHeight: tokens.lineHeightBase100,
        color: tokens.colorNeutralForeground2,
    },
    //popoverSurface: {
    //    display: 'none',
    //    ...Breakpoints.small({
    //        display: 'flex',
    //        flexDirection: 'column',
    //    }),
    //},
    selected: {
        backgroundColor: tokens.colorNeutralBackground4Selected,
        // Revert hover set by root
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground4Selected,
        },
    },
    protectedIcon: {
        color: tokens.colorPaletteLightGreenBorder1,
        verticalAlign: 'text-bottom',
        marginLeft: tokens.spacingHorizontalXS,
    },
    hide: {
        display: 'none',
    },
});

interface IChatListItemProps {
    id: string;
    isCollapsed: boolean;
    isConversationPaneFill: boolean;
    closeConversationPanel: () => void;
    setIsEdit: (isEdit: boolean) => void;
}

export const ChatListItem: FC<IChatListItemProps> = ({
    id,
    isCollapsed,
    isConversationPaneFill,
    closeConversationPanel,
    setIsEdit,
}) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const personas = usePersonas();
    const features = useAppSelector((state: RootState) => state.app.features);

    const isSelected: boolean = useAppSelector((state: RootState) => state.conversations.selectedId === id);

    const convoTitle = useAppSelector((state: RootState) => state.conversations.conversations[id].title);
    const convoLastUpdatedTimestamp = useAppSelector(
        (state: RootState) => state.conversations.conversations[id].lastUpdatedTimestamp,
    );
    const convoLastMessageSnippet = useAppSelector(
        (state: RootState) => state.conversations.conversations[id].lastMessageSnippet,
    );
    const convoCreatedOn = useAppSelector((state: RootState) => state.conversations.conversations[id].createdOn);
    const convoBotProfilePicture = useAppSelector(
        (state: RootState) => state.conversations.conversations[id].botProfilePicture,
    );

    const personaId = useAppSelector((state: RootState) => state.conversations.conversations[id].personaId ?? undefined);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const personaIconName = useAppSelector((state: RootState) => state.personas.personas[personaId ?? '']?.iconName);

    const [header, setHeader] = useState(getFriendlyChatName(convoLastMessageSnippet, convoTitle));
    React.useEffect(() => {
        setHeader(getFriendlyChatName(convoLastMessageSnippet, convoTitle));
    }, [convoTitle, convoLastMessageSnippet]);

    const [timestamp, setTimestamp] = useState(
        convoLastUpdatedTimestamp ?? (convoCreatedOn ? new Date(convoCreatedOn).getTime() : new Date().getTime()),
    );
    React.useEffect(() => {
        setTimestamp(
            convoLastUpdatedTimestamp ?? (convoCreatedOn ? new Date(convoCreatedOn).getTime() : new Date().getTime()),
        );
    }, [convoLastUpdatedTimestamp, convoCreatedOn]);

    const [preview, setPreview] = useState(
        convoLastMessageSnippet ? convoLastMessageSnippet : 'Click to start the chat',
    );
    React.useEffect(() => {
        setPreview(convoLastMessageSnippet ? convoLastMessageSnippet : 'Click to start the chat');
    }, [convoLastMessageSnippet]);

    React.useEffect(() => {
        // Check if the persona exists in the store
        // If not, attempt to fetch it from the server
        if (personaId !== undefined && !(personaId in store.getState().personas.personas)) {
            void personas.loadPersona(personaId, id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personaId]);

    const avatar: AvatarProps = (() => {
        if (personaId) {
            if (personaIconName) {
                return { icon: getIconByName(personaIconName) };
            }
            return { icon: <PersonRegular /> };
        }
        return { image: { src: convoBotProfilePicture } };
    })();

    const showPreview = !features[FeatureKeys.SimplifiedExperience].enabled && preview;
    const showActions = features[FeatureKeys.SimplifiedExperience].enabled && isSelected;

    const [editingTitle, setEditingTitle] = useState(false);

    const onClick = (_ev: any) => {
        dispatch(setSelectedConversation(id));
        setIsEdit(false);
        dispatch(setActiveRightHandPanel(RightHandPanel.Chat));
        if (isConversationPaneFill) {
            closeConversationPanel();
        }
    };

    const time = timestampToDateString(timestamp);
    return (
        <div
            className={mergeClasses(
                `${!isCollapsed ? classes.root : classes.rootSmall}`,
                isSelected && classes.selected,
            )}
            onClick={onClick}
            title={`Chat: ${header}`}
            aria-label={`Chat list item: ${header}`}
        >
            <Persona avatar={avatar} />
            {editingTitle ? (
                <EditChatName
                    name={header}
                    chatId={id}
                    exitEdits={() => {
                        setEditingTitle(false);
                    }}
                />
            ) : (
                <>
                    <div className={`${!isCollapsed ? classes.body : classes.hide}`}>
                        <div className={classes.header}>
                            <Text className={classes.title} title={header}>
                                {header}
                                {features[FeatureKeys.AzureContentSafety].enabled && (
                                    <ShieldTask16Regular className={classes.protectedIcon} />
                                )}
                            </Text>
                            {!features[FeatureKeys.SimplifiedExperience].enabled && (
                                <Text className={classes.timestamp} size={300}>
                                    {time}
                                </Text>
                            )}
                        </div>
                        {showPreview && (
                            <>
                                {
                                    <Text id={`message-preview-${id}`} size={200} className={classes.previewText}>
                                        {preview}
                                    </Text>
                                }
                            </>
                        )}
                    </div>
                    {showActions && (
                        <ListItemActions
                            chatId={id}
                            onEditTitleClick={() => {
                                setEditingTitle(true);
                            }}
                        />
                    )}
                </>
            )}
        </div>
    );
};
