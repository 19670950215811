export enum PersonaType {
    User,
    Global,
}

export interface IPersona {
    id: string;
    creatorUserId: string;
    name: string;
    instructions: string;
    iconName?: string;
    lastUpdatedDate: Date;
    type: PersonaType;
    isPinned: boolean;
}